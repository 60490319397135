.header {
  /* padding: 15px 20px; */
  margin: 0px auto;
  padding-top: 12px;
  text-transform: capitalize;
  box-sizing: border-box;
  top: 0;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.header.active {
  background: #f5f5f5f5;
  transition: all 0.3s ease-in-out;
}

/* .nav_items {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  margin-left: 20px;
  margin-right: 20px;
} */

/* .header li a {
  display: block;
  padding: 15px 20px;
  color: black;
  text-decoration: none;
} */

.header li a,
.header li a:hover {
  display: block;
  margin-left: auto;
  padding: 15px 20px;
  margin-left: 15px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 18px;
  color: black;
}

.header li a:hover {
  transform: scale(1.4);
}

.my-logo {
  display: inline-flex;
  width: 5.98em;
}

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  align-self: center;
  margin-right: 2%;
}

.header__logoContainer {
  display: flex;
  flex: none;
  float: left;
  font-size: 1.5em;
  text-decoration: none;
  line-height: normal;
  margin-left: 2%;
  align-items: center;
  justify-content: center;
}

.header__logoContainer>img {
  object-fit: scale-down;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  height: fit-content;
  margin-top: 0px;
}

/* menu icon */
.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  right: 0;
  padding: 35px 20px;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

/* .header:where(.menu-btn:checked) {
  background-color: #f5f5f5f5;
  transition: all 0.3s ease-in-out;
} */

.header .menu-btn:checked~.menu {
  max-height: 500px;
  background-color: initial;
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.header .menu-btn:checked~.menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
  top: 0;
}

@media (min-width: 55em) {
  .header li {
    float: left;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header .menu {
    max-height: none;
  }

  .header .menu-icon {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  .header {
    font-size: 12px;
  }

  /* .header li a {
    font-size: 15px;
  }
  .header li a:hover {
    transform: scale(1.02) !important;
    transition: all 0.1s ease !important;
  } */
}

@media only screen and (max-width: 880px) {
  .header {
    background: #f5f5f5f5 !important;
  }
}

@media only screen and (max-width: 856px) {
  .header li a {
    font-size: 17px;
  }

  .header li a:hover {
    transform: scale(1.02) !important;
    transition: all 0.1s ease !important;
  }
}

@media only screen and (max-width: 750px) {
  .header li a {
    font-size: 17px;
  }

  .header li a:hover {
    transform: scale(1.02) !important;
    transition: all 0.1s ease !important;
  }
}