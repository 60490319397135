.card {
    box-shadow: 0 0 25px 0 rgb(0 0 0 / 40%);
    background: rgba(235, 231, 231, 0.822);
    float: left;
    padding: 40px 30px;
    border-radius: 10px;
    /* text-align: center; */
    transition: all 0.8s ease;
    position: relative;
    width: 100%;
}

.card>h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
}

#imageIcon {
    display: block;
    margin: auto;
}

.card:hover {
    transform: scale(1.02);
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 60%);
}

.card a {
    color: black;
}

#linkSection {
    display: block;
    width: 100%;
    margin-top: 20px;
    align-items: center;
    text-align: center;
}

.card>span>h2 {
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
}

#link2 {
    margin-left: 10px;
}