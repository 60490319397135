* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  background-image: url("https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80");
  height: 100vh;
  width: 100%;
  /* margin-top: -1in; */
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  transition: all 1s ease-out;
}

:root {
  --text: rgb(0, 0, 0);
}